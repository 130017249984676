@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* allows background scrolling when dialog is active */
html:has(:is(.scrollable-background)) {
  overflow: initial !important;
}

.modal-box {
  .content-wrapper {
    padding-left: 18px;
    border-left-width: 6px;
    border-left-style: dashed;
    border-left-color: rgba(189, 59, 38, 0.9);
  }
  outline: solid 3px rgba(189, 59, 38, 0.9);
  //box-shadow: 0 0 30px 10px rgba(189, 59, 38, 0.9);
  padding-top: .5rem;
}

.modal::backdrop {
  background-color: #000c;
}
