img {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.column-center {
  @extend .column;
  align-items: center;
}

.row {
  display: flex; // flex
  gap: 10px; // gap-2.5
  align-items: center; // items-center
  flex-wrap: wrap; //
  max-width: 100%; // w-full
}

.row-nowrap {
  @extend .row;
  flex-wrap: nowrap;
}

.row-center {
  @extend .row;
  justify-content: center;
}

.wide {
  width: 100%;
}

.center {
  text-align: center;
}

.prose {
  max-width: 100%;
}

input.input,
textarea.textarea {
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
}

//@import "./base-view-transition";
